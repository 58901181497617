export function hasData(value) {
    if (value === '') return false
    if (value === null) return false
    if (value === undefined) return false
    if (Array.isArray(value) && value?.length < 1) return false
    return true
}

export function noData(value,alt,allowed_array=[]) {
    if (value === '') return alt
    if (value === null) return alt
    if (value === undefined) return alt
    if (Array.isArray(value) && value?.length < 1) return alt
    if (allowed_array?.length > 0 && allowed_array?.indexOf(value) == -1) return alt
    return value
}

